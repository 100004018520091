import './LoginPage.css';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import * as Realm from "realm-web";
import realmAuth from './RealmAuth';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);



  const handleSubmit = async (e) => {
    e.preventDefault();

    const success = await realmAuth.login(username, password);
    if (success) {
      const token = realmAuth.getToken();
      console.log('Token:', token);

      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        // Redirect to home page
        window.location.href = '/';
      }, 3000);
      // Redirect to authenticated page or store the token as needed
    } else {
      setShowErrorPopup(true);
      setTimeout(() => {
        setShowErrorPopup(false);
      }, 3000);

      // Display error message to the user
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //
  //   // Perform login logic here
  //   // You can validate the username and password and handle the login process
  //   // If login is successful, setLoggedIn(true) and store the token
  //
  //   // Simulating a successful login for demonstration purposes
  //   if (username === 'admin' && password === 'password') {
  //     setLoggedIn(true);
  //     // Store the token in local storage or state management library
  //     // localStorage.setItem('token', 'your_token_value');
  //   }
  //
  //   // Reset form fields
  //   setUsername('');
  //   setPassword('');
  // };

  if (loggedIn) {
    // Navigate to the authenticated page
    return <Navigate to="/authenticated" />;
  }

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Submit</button>
        </form>

        <div className="login-links">
         <p>
           Don't have an account? <a href="/signup">Sign up here</a>
         </p>
         <p>
           Forgot password? <a href="/forgot-password">Reset password</a>
         </p>
       </div>

      </div>

      {showSuccessPopup && (
        <div className="popup success-popup">
          <p>You're now logged in, welcome!</p>
        </div>
      )}

      {showErrorPopup && (
        <div className="popup error-popup">
          <p>Uh-oh, that didn't work. Please try again.</p>
        </div>
      )}


    </div>
  );
};

export default LoginPage;
