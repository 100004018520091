import React from 'react';
import logo from './lychee.png';
import './App.css';
import { BrowserRouter, Route, Routes , Link} from 'react-router-dom';

import Login from './LoginPage.js';
import Signup from './SignUpPage.js';
import Finder from './Finder.js';
import PasswordReset from './PasswordReset.js';

import realmAuth from './RealmAuth';

import * as Realm from "realm-web";
const app = new Realm.App({ id: "laichiapp-atevt" });
const credentials = Realm.Credentials.anonymous();

const domain = "/laichi"

const App = () => {
  // Keep the logged in Realm user in local state. This lets the app re-render
  // whenever the current user changes (e.g. logs in or logs out).
  const [user, setUser] = React.useState(app.currentUser);

  // If a user is logged in, show their details.
  // Otherwise, show the login screen.

  return (

    <div className="wrapper">

      <BrowserRouter >

      <header className="top-app-header">
      <div className="top-left-section">


      </div>
        <div className="top-right-section">
          <nav>
            <ul>
              <li>
                <Link to="/">Finder</Link>
              </li>
              <li>
                <Link to="/login">Login</Link>
              </li>
              <li>
                <Link to="/signup">Signup</Link>
              </li>
            </ul>
          </nav>
        </div>

      </header>
      <header className="logo-app-header">
        <Link to="/">  <img src={logo} alt="Lychee Logo" className="logo" />  </Link>
        <h1 className="title">Láichī</h1>
      </header>

        <Routes>

          <Route path='/forgot-password' element={<PasswordReset/>} />

          <Route path='/login' element={<Login/>} />

          <Route path='/signup' element={<Signup/>} />

          <Route path='/' element={<Finder/>} />


        </Routes>




      </BrowserRouter>
    </div>




  );
};
export default App;

// export default LoginPage;
// export default SignUpPage;
// export default App;




// {user ? <UserDetail user={user} /> : <Login setUser={setUser} />}
