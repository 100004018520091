import React, { useState } from 'react';
import './PasswordReset.css';

const PasswordReset = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform password reset logic here, such as sending the reset link to the provided email

    // Reset form field
    setEmail('');
  };

  return (
    <div className="password-reset-page">
      <div className="password-reset-container">
        <h2>Password Reset</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit">Reset Password</button>
        </form>
        <div className="password-reset-links">
          <p>
            Remembered your password? <a href="/login">Log in here</a>
          </p>
          <p>
            Don't have an account? <a href="/signup">Sign up here</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
