import React from 'react';
import Map from './map';

const MapSection = ({ latitude, longitude, showMap }) => {
  return (
    <section className="map-section">
      {showMap && (
        <div className="map-container">
          <Map
            id="myMap"
            options={{
              center: { lat: latitude, lng: longitude },
              zoom: 14
            }}
            onMapLoad={(map) => {
              var marker = new window.google.maps.Marker({
                position: { lat: latitude, lng: longitude },
                map: map,
                title: 'Hello from Láichī'
              });
            }}
          />
        </div>
      )}
    </section>
  );
};

export default MapSection;
