import * as Realm from "realm-web";

class RealmAuth {
  constructor() {
    this.realmApp = new Realm.App({ id: 'laichiapp-atevt' });
    this.token = null;
  }

  async login(email, password) {
    try {
      const credentials = Realm.Credentials.emailPassword(email, password);

      const user = await this.realmApp.logIn(credentials);
      this.token = user.accessToken;
      console.log('Login successful!', user);
      return true;
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    }
  }

  getToken() {
    return this.token;
  }
}

const realmAuth = new RealmAuth();
export default realmAuth;
