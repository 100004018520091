import React, { useState, useEffect } from 'react';
import './Finder.css';
import lycheeImage from './lychee.png';
import MapSection from './MapSection';
import ResponseSection from './ResponseSection';
import ContactForm from './ContactForm';

function Finder() {
  const [responseText, setResponseText] = useState('');
  const [latitude, setLatitude] = useState(40.7484);
  const [longitude, setLongitude] = useState(-73.9857);
  const [showMap, setShowMap] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [generateCount, setGenerateCount] = useState(0);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDnZHCNVuYH8lZSMZtuHzJ4677eUi6AE8w`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleButtonClick = () => {
    if (loggedIn || generateCount < 10) {
      fetch('https://us-east-1.aws.data.mongodb-api.com/app/fuddies-gzvwt/endpoint/generateResturant', {
        method: 'GET',
      })
        .then((response) => response.text())
        .then((data) => {
          setResponseText(JSON.stringify(JSON.parse(data).result[0]));
          setLatitude(JSON.parse(data).result[0].Latitude);
          setLongitude(JSON.parse(data).result[0].Longitude);
          setShowMap(true);
          setGenerateCount((prevCount) => prevCount + 1);
        })
        .catch((error) => console.log(error));
    } else {
      setShowLoginPrompt(true);
    }
  };

  const handleMapReset = () => {
    setShowMap(false);
    setLatitude(40.7484);
    setLongitude(-73.9857);
  };


  const handleLoginPromptClose = () => {
    setShowLoginPrompt(false);
  };

  return (
    <div className="app">
      <header className="app-header">
        <p className="description">Find a place to eat with our Random Restaurant Generator</p>
        <button onClick={() => { handleMapReset(); handleButtonClick(); }} className="cta-button">Generate</button>
      </header>

      <ResponseSection responseText={responseText} />

      <MapSection
        latitude={latitude}
        longitude={longitude}
        showMap={showMap}
      />

      {showLoginPrompt && (
        <div className="login-prompt">
          <div className="login-prompt-content">
            <span className="close" onClick={handleLoginPromptClose}>&times;</span>
            <h2>Login or Create an Account</h2>
            <p>Please login or create an account to generate more restaurants.</p>
            {/* Add login and signup components here */}
          </div>
        </div>
      )}

      <footer className="footer">
        <p className="footer-text">© 2023 <a href="https://laichi.app/">Láichī</a>. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Finder;
