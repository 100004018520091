import React from 'react';

const ResponseSection = ({ responseText }) => {
  return (
    <section className="response-section">
      {responseText && (
        <div className="response">
          <b>{JSON.parse(responseText).DBA}</b>
          <p>Address:</p>
          <p>
            {JSON.parse(responseText).BUILDING} {JSON.parse(responseText).STREET}
          </p>
          <p>
            {JSON.parse(responseText).BORO}, New York {JSON.parse(responseText).ZIPCODE}
          </p>
          <p>Phone: {JSON.parse(responseText).PHONE}</p>
          <p>Food: {JSON.parse(responseText)['CUISINE DESCRIPTION']}</p>
        </div>
      )}
    </section>
  );
};

export default ResponseSection;
